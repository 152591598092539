const Layout = () => import('@/layout/index.vue')

export default {
  path: '/paySetting',
  component: Layout,
  redirect: '/paySetting/paySettingList',
  // component:()=>import("@/views/productManage.vue"),
  name: 'paySetting',
  meta: {
    title: '支付通道配置',
    auth: [1]
  },
  children: [
    {
      path: 'paySettingList',
      name: 'paySettingList',
      component: () => import('@/views/paySetting/index.vue'),
      meta: {
        title: '支付通道配置',
        sidebar: false,
        activeMenu: '/paySetting'
      }
    },
    {
      path: 'payEdit',
      name: 'payEdit',
      component: () => import('@/views/paySetting/components/edit.vue'),
      meta: {
        title: '支付通道配置编辑',
        sidebar: false,
        activeMenu: '/paySetting'
      }
    }
  ]
}
