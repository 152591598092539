const Layout = () => import("@/layout/index.vue");

export default {
	path: "/channelLdy",
	component: Layout,
	redirect: "/channelLdy/channelList",
	// component:()=>import("@/views/productManage.vue"),
	name: "channelLdy",
	meta: {
		title: "渠道、落地页",
    auth: [1,7]
	},
	children: [
		{
			path: "channelList",
			name: "channelList",
			component: () => import("@/views/channelLdy/channelManage/index.vue"),
			meta: {
				title: "渠道管理",
				activeMenu: "/channelLdy/channelList",
			},
		},
		{
			path: "channelAdd",
			name: "channelAdd",
			component: () => import("@/views/channelLdy/channelManage/components/add.vue"),
			meta: {
				title: "添加渠道",
				sidebar: false,
				activeMenu: "/channelLdy/channelList",
			},
		},
		{
			path: "channelEdit",
			name: "channelEdit",
			component: () =>
				import("@/views/channelLdy/channelManage/components/edit.vue"),
			meta: {
				title: "编辑渠道",
				sidebar: false,
				activeMenu: "/channelLdy/channelList",
			},
		}, {
			path: "ldyList",
			name: "ldyList",
			component: () => import("@/views/channelLdy/ldyManage/index.vue"),
			meta: {
				title: "落地页管理",
				activeMenu: "/channelLdy/ldyList",
			},
		},
		{
			path: "ldyAdd",
			name: "ldyAdd",
			component: () =>
				import("@/views/channelLdy/ldyManage/components/add.vue"),
			meta: {
				title: "添加落地页",
				sidebar: false,
				activeMenu: "/channelLdy/ldyList",
			},
		},
		{
			path: "ldyEdit",
			name: "ldyEdit",
			component: () => import("@/views/channelLdy/ldyManage/components/edit.vue"),
			meta: {
				title: "编辑落地页",
				sidebar: false,
				activeMenu: "/channelLdy/ldyList",
			},
		},
	],
};
