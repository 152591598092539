import pinia from '@/store'
import useSettingsStore from '@/store/modules/settings'
// 以上作废
import user from './modules/user'
import signProductSetting from './modules/signProductSetting'
import signVipManage from './modules/signVipManage'
import paySetting from './modules/paySetting'
import payPlatformSetting from './modules/payPlatformSetting'
import deductionOrder from './modules/deductionOrder'
import refoundManage from './modules/refoundManage'
import channelLdy from './modules/channelLdy'
import Escalationlog from './modules/Escalationlog'
import dataSee from './modules/dataSee'
import operationRecords from '@/router/modules/operationRecords'
import equity from '@/router/modules/equity'
import phone from './modules/phone'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'

// 固定路由（默认路由）
let constantRoutes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/signVipManage_f",
    name: "signVipManage_f",
    component: () => import("@/views/signVipManage_f.vue"),
    meta: {
      title: "签约会员管理",
    },
  },
  {
    path: "/deductionOrder_f",
    name: "deductionOrder_f",
    component: () => import("@/views/deductionOrder_f.vue"),
    meta: {
      title: "扣款订单管理",
    },
  },
];

// 系统路由
let systemRoutes = [
  {
    path: "/dashboard",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        // component: () => import("@/views/signVipManage/index.vue"),
        component: () => import("@/views/index.vue"),
        meta: {
          title: () => {
            return useSettingsStore().dashboard.title;
          },
          breadcrumb: false,
        },
      },
    ],
  },
  {
    path: "/personal",
    component: () => import("@/layout/index.vue"),
    redirect: "/personal/setting",
    children: [
      {
        path: "setting",
        name: "personalSetting",
        component: () => import("@/views/personal/setting.vue"),
        meta: {
          title: "个人设置",
          i18n: "route.personal.setting",
          cache: "personalEditPassword",
        },
      },
      {
        path: "edit/password",
        name: "personalEditPassword",
        component: () => import("@/views/personal/edit.password.vue"),
        meta: {
          title: "修改密码",
          i18n: "route.personal.editpassword",
        },
      },
      {
        path: "edit/personalInfor",
        name: "personalInfor",
        component: () => import("@/views/personal/personalInfor.vue"),
        meta: {
          title: "个人信息",
          i18n: "route.personal.editpassword",
        },
      },
    ],
  },
  {
    path: "/reload",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "",
        name: "reload",
        component: () => import("@/views/reload.vue"),
        meta: {
          title: "重新加载",
          breadcrumb: false,
        },
      },
    ],
  },
];

// 动态路由（异步路由、导航栏路由）
let asyncRoutes = [
  {
    meta: {
      title: "演示",
      icon: "sidebar-default",
    },
    children: [
      signVipManage,
      deductionOrder,
      signProductSetting,
      channelLdy,
      // channelManage,
      // ldyManage,
      payPlatformSetting,
      paySetting,
      user,
      refoundManage,
      Escalationlog,
      dataSee,
      operationRecords,
      equity,
      phone,
    ],
  },
];


let backRoutes = [
  {
    meta: {
      title: "演示",
      icon: "sidebar-default",
    },
    children: [
    ],
  },
];

if (useSettingsStore(pinia).app.routeBaseOn === "filesystem") {
  constantRoutes = generatedRoutes.filter((item) => {
    return item.meta?.enabled !== false && item.meta?.constant === true;
  });
  asyncRoutes = setupLayouts(
    generatedRoutes.filter((item) => {
      return (
        item.meta?.enabled !== false &&
        item.meta?.constant !== true &&
        item.meta?.layout !== false
      );
    })
  );
}

// 404路由
const notFoundRoute = {
  path: "/:all(.*)*",
  name: "notFound",
  component: () => import("@/views/[...all].vue"),
  meta: {
    title: "找不到页面",
  },
};

export { constantRoutes, systemRoutes, asyncRoutes, notFoundRoute,backRoutes };
