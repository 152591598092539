const Layout = () => import("@/layout/index.vue");

export default {
  path: "/phone",
  component: Layout,
  redirect: "/phone/phoneList",
  // component:()=>import("@/views/productManage.vue"),
  name: "phone",
  meta: {
    title: "手机号白名单",
  },
  children: [
    {
      path: "phoneList",
      name: "phoneList",
      component: () => import("@/views/phoneList/index.vue"),
      meta: {
        title: "手机号白名单",
        sidebar: false,
        activeMenu: "/phone",
      },
    },
  ],
};
