const Layout = () => import('@/layout/index.vue')

export default {
  path: '/payPlatformSetting',
  component: Layout,
  redirect: '/payPlatformSetting/payPlatformList',
  // component:()=>import("@/views/productManage.vue"),
  name: 'payPlatformSetting',
  meta: {
    title: '支付平台配置',
    auth: [1]
  },
  children: [
    {
      path: 'payPlatformList',
      name: 'payPlatformList',
      component: () => import('@/views/payPlatformSetting/index.vue'),
      meta: {
        title: '支付平台配置',
        sidebar: false,
        activeMenu: '/payPlatformSetting'
      }
    },
    {
      path: 'payPlatformEdit',
      name: 'payPlatformEdit',
      component: () =>
        import('@/views/payPlatformSetting/components/edit.vue'),
      meta: {
        title: '编辑支付平台配置',
        sidebar: false,
        activeMenu: '/payPlatformSetting'
      }
    }
  ]
}
