


export const layouts = {
'index': () => import('/D:/workspace/managementbackend-new/src/layout/index.vue'),
'components/AppSetting/index': () => import('/D:/workspace/managementbackend-new/src/layout/components/AppSetting/index.vue'),
'components/Header/index': () => import('/D:/workspace/managementbackend-new/src/layout/components/Header/index.vue'),
'components/MainSidebar/index': () => import('/D:/workspace/managementbackend-new/src/layout/components/MainSidebar/index.vue'),
'components/Logo/index': () => import('/D:/workspace/managementbackend-new/src/layout/components/Logo/index.vue'),
'components/Search/index': () => import('/D:/workspace/managementbackend-new/src/layout/components/Search/index.vue'),
'components/SubSidebar/index': () => import('/D:/workspace/managementbackend-new/src/layout/components/SubSidebar/index.vue'),
'components/SidebarItem/index': () => import('/D:/workspace/managementbackend-new/src/layout/components/SidebarItem/index.vue'),
'components/Tools/index': () => import('/D:/workspace/managementbackend-new/src/layout/components/Tools/index.vue'),
'components/Topbar/index': () => import('/D:/workspace/managementbackend-new/src/layout/components/Topbar/index.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'index'],
      children: [ {...route, path: ''} ],
    }
  })
}
