const Layout = () => import('@/layout/index.vue')

export default {
  path: '/signVipManage',
  component: Layout,
  redirect: '/signVipManage/signVipList',
  // component:()=>import("@/views/productManage.vue"),
  name: 'signVipManage',
  meta: {
    title: '签约会员管理',
    auth: [1]
  },
  children: [
    {
      path: 'signVipList',
      name: 'signVipList',
      component: () => import('@/views/signVipManage/index.vue'),
      meta: {
        title: '签约会员管理',
        sidebar: false,
        activeMenu: '/signVipManage'
      }
    }
  ]
}
