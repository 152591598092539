const Layout = () => import("@/layout/index.vue");

export default {
	path: "/Escalationlog",
	component: Layout,
	redirect: "/Escalationlog/logList",
	// component:()=>import("@/views/productManage.vue"),
	name: "Escalationlog",
	meta: {
		title: "上报记录",
    auth: [1]
	},
	children: [
		{
			path: "logList",
			name: "logList",
			component: () => import("@/views/Escalationlog/index.vue"),
			meta: {
				title: "上报记录",
				sidebar: false,
				activeMenu: "/Escalationlog",
			},
		},
	],
};
