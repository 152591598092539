const Layout = () => import('@/layout/index.vue')

export default {
  path: '/deductionOrder',
  component: Layout,
  redirect: '/deductionOrder/deductionOrderList',
  // component:()=>import("@/views/productManage.vue"),
  name: 'deductionOrder',
  meta: {
    title: '扣款订单管理',
    auth: [1, 6, 8]
  },
  children: [
    {
      path: 'deductionOrderList',
      name: 'deductionOrderList',
      component: () => import('@/views/deductionOrder/index.vue'),
      meta: {
        title: '扣款订单管理',
        sidebar: false,
        activeMenu: '/deductionOrder'
      }
    }
  ]
}
