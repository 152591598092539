const Layout = () => import('@/layout/index.vue')

export default {
  path: '/user',
  component: Layout,
  redirect: '/user/usermanage',
  // component:()=>import("@/views/productManage.vue"),
  name: 'user',
  meta: {
    title: '用户管理',
    auth: [1, 6, 8]
  },
  children: [
    {
      path: 'usermanage',
      name: 'usermanage',
      component: () => import('@/views/user/userManage/index.vue'),
      meta: {
        title: '用户列表',
        sidebar: false,
        activeMenu: '/user'
      }
    }
  ]
}
