import api from '@/api'

import useRouteStore from './route'
import useMenuStore from './menu'
import { post } from '@/api/request'
import { ElMessage } from 'element-plus'

const useUserStore = defineStore(
  // 唯一ID
  'user',
  {
    state: () => ({
      account: localStorage.account || '',
      token: localStorage.token || '',
      failure_time: localStorage.failure_time || '',
      permissions: []
    }),
    getters: {
      isLogin: (state) => {
        let retn = false
        if (state.token) {
          if (new Date().getTime() < state.failure_time * 1000) {
            retn = true
          }
        }
        return retn
      }
    },
    actions: {
      login (data) {
        return new Promise((resolve, reject) => {
          // 通过 mock 进行登录
          post('/admin/login', {
            account: data.account,
            psw: data.password
          })
            .then((res) => {
              if (res.data.code == 200) {
                ElMessage.success('登录成功')
                const failure_time =
                  Math.ceil(new Date().getTime() / 1000) +
                  24 * 60 * 60 * 7
                localStorage.setItem('username', data.account)
                localStorage.setItem(
                  'failure_time',
                  failure_time
                )
                localStorage.setItem('token', data.account)
                localStorage.setItem('account', data.account)
                this.username = data.account
                this.failure_time = failure_time
                this.account = data.account
                this.token = data.account
                resolve(res)
              } else {
                ElMessage.error('用户名或密码错误')
                resolve(res)
              }
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      logout (a) {
        return new Promise((resolve) => {
          const routeStore = useRouteStore()
          const menuStore = useMenuStore()
          localStorage.removeItem('account')
          localStorage.removeItem('token')
          localStorage.removeItem('failure_time')
          localStorage.removeItem('username')
          this.account = ''
          this.token = ''
          this.failure_time = ''
          this.username = ''
          routeStore.removeRoutes()
          menuStore.setActived(0)
          if (a != 1) {
            post('/admin/logout')
          }
          resolve()
        })
      },
      // 获取我的权限
      getPermissions () {
        return new Promise((resolve) => {
          // 通过 mock 获取权限
          api.get('admin/userMenu/getPermission', {
            baseURL: '/api/',
            params: {
              account: this.account
            }
          }).then((res) => {
            // this.permissions = res.data.permissions
            // this.permissions = []
            // this.permissions.push(res.data.data)
            this.permissions = [res.data.data]
            // console.log('查看权限', this.permissions)
            // console.log(this.permissions);
            // resolve(this.permissions)
            // resolve(res.data.permissions)
            resolve([res.data.data])
          })
        })
      },
      editPassword (data) {
        return new Promise((resolve) => {
          api.post(
            'member/edit/password',
            {
              account: this.account,
              password: data.password,
              newpassword: data.newpassword
            },
            {
              baseURL: '/mock/'
            }
          ).then(() => {
            resolve()
          })
        })
      }
    }
  }
)

export default useUserStore
