import axios from "@/api/index";
// import { api2 } from "@/api/index";
export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url,
            data,
        })
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
// export function post1(url, data) {
//     return new Promise((resolve, reject) => {
//         api2({
//             method: "post",
//             url,
//             data: data,
//             headers: {
//                 "Content-Type": "application/json;charset=UTF-8",
//             },
//         })
//             .then((data) => {
//                 resolve(data);
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });
// }
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url,
            params,
        })
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
export function put(url, data) {
    return new Promise((resolve, reject) => {
        axios({
            method: "put",
            url,
            data,
        })
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
export function del(url, data) {
    return new Promise((resolve, reject) => {
        axios({
            method: "delete",
            url,
            data,
        })
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
export function upload(url, data) {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url,
            data,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
