const Layout = () => import("@/layout/index.vue");

export default {
  path: "/dataSee",
  component: Layout,
  redirect: "/dataSee/dataDetail",
  // component:()=>import("@/views/productManage.vue"),
  name: "dataSee",
  meta: {
    title: "扣款数据看板",
    auth: [1]
  },
  children: [
    {
      path: "dataDetail",
      name: "dataDetail",
      component: () => import("@/views/dataSee/index.vue"),
      meta: {
        title: "扣款数据看板",
        sidebar: false,
        activeMenu: "/dataSee",
      },
    },
    {
      path: "signUserDetail",
      name: "signUserDetail",
      component: () => import("@/views/dataSee/components/Signed/detail.vue"),
      meta: {
        title: "签约用户数",
        sidebar: false,
        activeMenu: "/dataSee",
      },
    },
  ],
};
