const Layout = () => import("@/layout/index.vue");

export default {
  path: "/operationRecords",
  component: Layout,
  redirect: "/operationRecords/operationRecords",
  // component:()=>import("@/views/productManage.vue"),
  name: "operationRecords",
  meta: {
    title: "操作记录",
    auth: [1]
  },
  children: [
    {
      path: "operationRecords",
      name: "operationRecords",
      component: () => import("@/views/operationRecords/index.vue"),
      meta: {
        title: "操作记录",
        sidebar: false,
        activeMenu: "/operationRecords",
      },
    },
  ],
};
