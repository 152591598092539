const Layout = () => import("@/layout/index.vue");

export default {
  path: "/equity",
  component: Layout,
  redirect: "/equity/equityReceive",
  // component:()=>import("@/views/productManage.vue"),
  name: "equity",
  meta: {
    title: "权益",
    auth: [1],
  },
  children: [
    {
      path: "equityReceive",
      name: "equityReceive",
      component: () => import("@/views/equity/equityReceive/index.vue"),
      meta: {
        title: "权益领取记录",
        activeMenu: "/equity/equityReceive",
      },
    },
    {
      path: "JDCard",
      name: "JDCard",
      meta: {
        title: "京东卡密",
      },
      children: [
        {
          path: "CardList",
          name: "CardList",
          component: () => import("@/views/equity/JDCard/CardList/index.vue"),
          meta: {
            title: "卡密列表",
            activeMenu: "/equity/JDCard/CardList",
          },
        },
        {
          path: "CardLog",
          name: "CardLog",
          component: () => import("@/views/equity/JDCard/CardLog/index.vue"),
          meta: {
            title: "卡密领取记录",
            activeMenu: "/equity/JDCard/CardLog",
          },
        },
      ],
    },
  ],
};
