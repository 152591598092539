const Layout = () => import('@/layout/index.vue')

export default {
  path: '/signProductSetting',
  component: Layout,
  redirect: '/signProductSetting/signProductList',
  // component:()=>import("@/views/productManage.vue"),
  name: 'signProductSetting',
  meta: {
    title: '签约产品配置',
    auth: [1,7]
  },
  children: [
    {
      path: 'signProductList',
      name: 'signProductList',
      component: () => import('@/views/signProductSetting/index.vue'),
      meta: {
        title: '签约产品配置',
        sidebar: false,
        activeMenu: '/signProductSetting'
        // cache: [10],
      }
    },
    {
      path: 'signProductAdd',
      name: 'signProductAdd',
      component: () =>
        import('@/views/signProductSetting/components/add.vue'),
      meta: {
        title: '签约产品添加',
        sidebar: false,
        activeMenu: '/signProductSetting'
      }
    },
    {
      path: 'signProductEdit',
      name: 'signProductEdit',
      component: () =>
        import('@/views/signProductSetting/components/edit.vue'),
      meta: {
        title: '签约产品编辑',
        sidebar: false,
        activeMenu: '/signProductSetting'
      }
    }
  ]
}
