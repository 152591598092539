const Layout = () => import('@/layout/index.vue')

export default {
  path: '/refoundManage',
  component: Layout,
  redirect: '/refoundManage/refoundList',
  // component:()=>import("@/views/productManage.vue"),
  name: 'refoundManage',
  meta: {
    title: '退款订单',
    auth: [1,6]
  },
  children: [
    {
      path: 'refoundList',
      name: 'refoundList',
      component: () => import('@/views/refoundManage/index.vue'),
      meta: {
        title: '退款订单',
        sidebar: false,
        activeMenu: '/refoundManage'
      }
    }
  ]
}
